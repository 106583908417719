@use "variables" as v;

.tracking-container {
    min-height: 80vh;
}

.tracking-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    background-color: v.$white;
    width: 98%;
    margin-left: 1%;
    padding-bottom: 2.5%
}

.tracking-card {
    width: 50% !important;
}

#tracking-content {
    background-color: v.$lightGrey
}

#tracking-grid-row {
    display: flex;
    align-items: center
}

#tracking-header {
    font-size: 18px
}

#tracking-button {
    min-width: 200px;
    float: right
}

#tracking-selected {
    font-weight: bold;
}

#tracking-message {
    min-height: 150;
    max-height: 250;
}